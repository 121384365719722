.twistoPayHeaderBanner {
  text-align: center;
  background: #F6E7E4; }
  .twistoPayHeaderBanner img {
    width: 100%;
    max-width: 728px; }

.twistoPayFooterBanner {
  margin-bottom: 20px;
  margin-top: 20px; }

.twistoProductDetailBanner {
  display: flex;
  align-items: center; }
  .twistoProductDetailBannerLogo {
    max-width: 90px;
    margin-right: 10px; }
  .twistoProductDetailBannerButton {
    text-decoration: underline;
    cursor: pointer; }

.twistoProductDetailBannerOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px; }
  .twistoProductDetailBannerOverlay.is-open {
    display: flex; }
  .twistoProductDetailBannerOverlayImgDesktop {
    width: 100%;
    max-width: 600px; }
    @media (max-width: 700px) {
      .twistoProductDetailBannerOverlayImgDesktop {
        display: none; } }
  .twistoProductDetailBannerOverlayImgMobile {
    width: 100%;
    max-width: 50vh;
    display: none; }
    @media (max-width: 700px) {
      .twistoProductDetailBannerOverlayImgMobile {
        display: block; } }

.twistoPayThanksPageOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px; }
  .twistoPayThanksPageOverlay h1 {
    color: #F6E7E4; }
  .twistoPayThanksPageOverlay.is-close {
    display: none; }
